import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../components/common'

const NotFoundPage = () => (
    <Layout>

        <section className="outer error-content">
            <div className="inner">
                <section className="error-message">
                    <h1 className="error-code">Lỗi 404</h1>
                    <p className="error-description">Trang không tồn tại hoặc đã bị xoá.</p>
                    <Link className="error-link" to="/">Quay về trang chủ →</Link>
                </section>
            </div>
        </section>

    </Layout>
)

export default NotFoundPage
